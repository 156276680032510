import { useFormContext } from 'react-hook-form';
import Markdown from '@/components/Markdown/Markdown';
import ReadOnlyInput from '@/components/Input/ReadOnlyInput/ReadOnlyInput';
import { useFieldError } from '@/hooks/useFieldError';
import classes from './Input.module.scss';
import classnames from 'classnames';
import { InputProps } from '@/interfaces/fields';
import { useState } from 'react';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { useFormReadonly } from '../FormWrapper/components/FormReadonlyProvider';

const Input = ({
  name,
  text,
  prefix,
  suffix,
  serverNames,
  hiddenlabel,
  readonly,
  width,
  inputOptions,
  autocomplete,
  type,
  ...rest
}: InputProps) => {
  const { register, getValues } = useFormContext();
  const value = getValues(name);
  const error = useFieldError(name, text, serverNames);
  const [showPassword, setShowPassword] = useState(false);
  const { readonly: formReadonly } = useFormReadonly();
  const isReadonly = readonly || formReadonly;

  if (isReadonly) {
    return (
      <ReadOnlyInput
        value={value}
        name={name}
        text={text}
        hiddenlabel={hiddenlabel}
        {...rest}
      />
    );
  }

  return (
    <label>
      {!hiddenlabel && <div className={classes.label}>{text?.label}</div>}
      <div className={classes.inputCont}>
        {prefix && <div className={classes.prefix}>{prefix}</div>}
        <input
          {...rest}
          type={
            type === 'password' ? (showPassword ? 'text' : 'password') : type
          }
          placeholder={text?.placeholder}
          className={classnames(classes.input, {
            [classes.inputError]: !!error,
            [classes.passwordInput]: type === 'password',
          })}
          style={{ width: `${width}%` }}
          data-testid={name}
          autoComplete={autocomplete}
          {...register(name, inputOptions)}
        />
        {type === 'password' && (
          <McButton
            fit={'small'}
            icon={showPassword ? 'eye-slash' : 'eye'}
            variant={'plain'}
            appearance={'neutral'}
            hiddenlabel
            click={() => setShowPassword(!showPassword)}
          />
        )}
        {suffix && <div className={classes.suffix}>{suffix}</div>}
      </div>
      {!!error && <div className={classes.error}>{error}</div>}
      {text?.hint && (
        <Markdown isHint={true} className={classes.markdown}>
          {text?.hint}
        </Markdown>
      )}
    </label>
  );
};

export default Input;
