/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';

/**
 * 
 * @export
 * @interface ApplicationRequestMetaResponseDto
 */
export interface ApplicationRequestMetaResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequestMetaResponseDto
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequestMetaResponseDto
     */
    applicationName?: string;
    /**
     * 
     * @type {Set<OwnerDto>}
     * @memberof ApplicationRequestMetaResponseDto
     */
    owners?: Set<OwnerDto>;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequestMetaResponseDto
     */
    platformId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequestMetaResponseDto
     */
    platformName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequestMetaResponseDto
     */
    requestedPlatformName?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationRequestMetaResponseDto
     */
    description?: string;
}

/**
 * Check if a given object implements the ApplicationRequestMetaResponseDto interface.
 */
export function instanceOfApplicationRequestMetaResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApplicationRequestMetaResponseDtoFromJSON(json: any): ApplicationRequestMetaResponseDto {
    return ApplicationRequestMetaResponseDtoFromJSONTyped(json, false);
}

export function ApplicationRequestMetaResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationRequestMetaResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'applicationName': !exists(json, 'applicationName') ? undefined : json['applicationName'],
        'owners': !exists(json, 'owners') ? undefined : (new Set((json['owners'] as Array<any>).map(OwnerDtoFromJSON))),
        'platformId': !exists(json, 'platformId') ? undefined : json['platformId'],
        'platformName': !exists(json, 'platformName') ? undefined : json['platformName'],
        'requestedPlatformName': !exists(json, 'requestedPlatformName') ? undefined : json['requestedPlatformName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function ApplicationRequestMetaResponseDtoToJSON(value?: ApplicationRequestMetaResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationId': value.applicationId,
        'applicationName': value.applicationName,
        'owners': value.owners === undefined ? undefined : (Array.from(value.owners as Set<any>).map(OwnerDtoToJSON)),
        'platformId': value.platformId,
        'platformName': value.platformName,
        'requestedPlatformName': value.requestedPlatformName,
        'description': value.description,
    };
}

