import { getUrlFromParams } from '@/helpers/urls';
import { Link } from 'react-router-dom';
import { InternalLinkProps } from './Md';

export function InternalLink({ node, ...props }: InternalLinkProps) {
  const url = getUrlFromParams(node.properties);
  if (!url) {
    return props.children;
  }
  return <Link to={url}>{props.children}</Link>;
}
