import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@/components/Modal/Modal';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import classes from './ConfirmationModal.module.css';
import { ReactNode } from 'react';
import { ButtonAppearance } from '@maersk-global/mds-components-core/mc-button/types';

interface DeleteConfirmationModalProps {
  className?: string;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  title: string;
  text: string | ReactNode;
  open: boolean;
  submitText?: string;
  submitAppearance?: ButtonAppearance;
  disabled?: boolean;
}

const ConfirmationModal = ({
  className,
  onClose,
  onSubmit,
  title,
  text,
  open,
  submitText = 'Delete',
  submitAppearance = 'error',
  disabled = false,
}: DeleteConfirmationModalProps) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit().finally(() => setLoading(false));
  };

  return (
    <Modal
      open={open}
      closed={onClose}
      className={`${classes.modal} ${className ?? ''}`}
      persistError
    >
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <div>{text}</div>
      </ModalBody>
      <ModalFooter>
        <McButton
          click={onClose}
          label={'Cancel'}
          appearance={'neutral'}
          data-testid={'confirm-modal-cancel'}
        />
        <McButton
          click={handleSubmit}
          loading={loading}
          disabled={disabled || loading}
          appearance={submitAppearance}
          data-testid={'confirm-modal-confirm'}
        >
          {submitText}
        </McButton>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
