/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PortfolioMetaResponseDto } from './PortfolioMetaResponseDto';
import {
    PortfolioMetaResponseDtoFromJSON,
    PortfolioMetaResponseDtoFromJSONTyped,
    PortfolioMetaResponseDtoToJSON,
} from './PortfolioMetaResponseDto';

/**
 * Application's platform information
 * @export
 * @interface PlatformMetaResponseDto
 */
export interface PlatformMetaResponseDto {
    /**
     * Application's platform id
     * @type {string}
     * @memberof PlatformMetaResponseDto
     */
    platformId: string;
    /**
     * Application's platform id
     * @type {string}
     * @memberof PlatformMetaResponseDto
     */
    platformName: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformMetaResponseDto
     */
    platformType?: string;
    /**
     * 
     * @type {PortfolioMetaResponseDto}
     * @memberof PlatformMetaResponseDto
     */
    portfolio: PortfolioMetaResponseDto;
}

/**
 * Check if a given object implements the PlatformMetaResponseDto interface.
 */
export function instanceOfPlatformMetaResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "platformId" in value;
    isInstance = isInstance && "platformName" in value;
    isInstance = isInstance && "portfolio" in value;

    return isInstance;
}

export function PlatformMetaResponseDtoFromJSON(json: any): PlatformMetaResponseDto {
    return PlatformMetaResponseDtoFromJSONTyped(json, false);
}

export function PlatformMetaResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlatformMetaResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'platformId': json['platformId'],
        'platformName': json['platformName'],
        'platformType': !exists(json, 'platformType') ? undefined : json['platformType'],
        'portfolio': PortfolioMetaResponseDtoFromJSON(json['portfolio']),
    };
}

export function PlatformMetaResponseDtoToJSON(value?: PlatformMetaResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'platformId': value.platformId,
        'platformName': value.platformName,
        'platformType': value.platformType,
        'portfolio': PortfolioMetaResponseDtoToJSON(value.portfolio),
    };
}

