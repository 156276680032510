import { createContext, useContext } from 'react';

export const FormReadonlyContext = createContext<{
  readonly: boolean;
  setReadonly: (value: boolean) => void;
}>({ readonly: false, setReadonly: () => {} });

export const FormReadonlyProvider = ({
  children,
  initialReadonly = false,
}: {
  children: React.ReactNode;
  initialReadonly?: boolean;
}) => {
  return (
    <FormReadonlyContext.Provider
      value={{
        readonly: initialReadonly,
        setReadonly: () => {}, // No-op since we're controlled by prop
      }}
    >
      {children}
    </FormReadonlyContext.Provider>
  );
};

export const useFormReadonly = () => {
  return useContext(FormReadonlyContext);
};
