import { getValue } from '@/helpers/utils';
import text from '@text';

export interface TranslationKeyProps {
  node: {
    properties: {
      key: string;
    };
    children: React.ReactNode;
  };
  children: React.ReactNode;
}

export function TranslationKey({ node, ...props }: TranslationKeyProps) {
  // const result = text[node.properties.key as keyof typeof text];
  if (!node.properties.key) return props.children;
  const result = getValue(node.properties.key, text).trim();
  if (!result || result.length === 0) {
    return node.properties.key.split('.').pop();
  }
  return result;
}
