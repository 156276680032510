import classNames from 'classnames';
import classes from './FormInputGroup.module.scss';
import { CSSProperties, ReactNode } from 'react';

interface FormInputGroupProps {
  children: ReactNode;
  appearance?: 'plain' | 'primary' | 'secondary' | 'tetriary';
  bordered?: boolean;
  width?: 'narrow' | 'normal' | 'full';
  gap?: 'small' | 'medium' | 'large';
  style?: CSSProperties;
  title?: string;
}

export const FormInputGroup = ({
  children,
  appearance = 'plain',
  bordered = false,
  width,
  gap = 'small',
  style,
  title,
}: FormInputGroupProps) => {
  return (
    <div
      className={classNames(classes.container, {
        [classes.primary]: appearance === 'primary',
        [classes.secondary]: appearance === 'secondary',
        [classes.tetriary]: appearance === 'tetriary',
        [classes.plain]: appearance === 'plain',
        [classes.bordered]: bordered,
        [classes[`width-${width || 'full'}`]]: true,
        [classes[`gap-${gap}`]]: true,
      })}
    >
      {title && <h4 className={classes.title}>{title}</h4>}
      <div className={classNames(classes.wrapper)} style={style}>
        {children}
      </div>
    </div>
  );
};
