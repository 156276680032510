/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InformationClassificationResponseDto } from './InformationClassificationResponseDto';
import {
    InformationClassificationResponseDtoFromJSON,
    InformationClassificationResponseDtoFromJSONTyped,
    InformationClassificationResponseDtoToJSON,
} from './InformationClassificationResponseDto';
import type { LabelResponseDto } from './LabelResponseDto';
import {
    LabelResponseDtoFromJSON,
    LabelResponseDtoFromJSONTyped,
    LabelResponseDtoToJSON,
} from './LabelResponseDto';
import type { MipLayerResponseDto } from './MipLayerResponseDto';
import {
    MipLayerResponseDtoFromJSON,
    MipLayerResponseDtoFromJSONTyped,
    MipLayerResponseDtoToJSON,
} from './MipLayerResponseDto';
import type { MvcCategoryResponseDto } from './MvcCategoryResponseDto';
import {
    MvcCategoryResponseDtoFromJSON,
    MvcCategoryResponseDtoFromJSONTyped,
    MvcCategoryResponseDtoToJSON,
} from './MvcCategoryResponseDto';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';
import type { PlatformMetaResponseDto } from './PlatformMetaResponseDto';
import {
    PlatformMetaResponseDtoFromJSON,
    PlatformMetaResponseDtoFromJSONTyped,
    PlatformMetaResponseDtoToJSON,
} from './PlatformMetaResponseDto';
import type { StrategicIntentResponseDto } from './StrategicIntentResponseDto';
import {
    StrategicIntentResponseDtoFromJSON,
    StrategicIntentResponseDtoFromJSONTyped,
    StrategicIntentResponseDtoToJSON,
} from './StrategicIntentResponseDto';

/**
 * 
 * @export
 * @interface ApplicationResponseDto
 */
export interface ApplicationResponseDto {
    /**
     * Application Id
     * @type {string}
     * @memberof ApplicationResponseDto
     */
    applicationId: string;
    /**
     * Application Name
     * @type {string}
     * @memberof ApplicationResponseDto
     */
    applicationName: string;
    /**
     * Application Description
     * @type {string}
     * @memberof ApplicationResponseDto
     */
    description: string;
    /**
     * 
     * @type {Set<OwnerDto>}
     * @memberof ApplicationResponseDto
     */
    owners?: Set<OwnerDto>;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponseDto
     */
    targetDecommissioningDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponseDto
     */
    decommissionedDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponseDto
     */
    locallyManaged?: boolean;
    /**
     * 
     * @type {MvcCategoryResponseDto}
     * @memberof ApplicationResponseDto
     */
    criticalityCategory?: MvcCategoryResponseDto;
    /**
     * 
     * @type {MipLayerResponseDto}
     * @memberof ApplicationResponseDto
     */
    mipLayer?: MipLayerResponseDto;
    /**
     * 
     * @type {StrategicIntentResponseDto}
     * @memberof ApplicationResponseDto
     */
    strategicIntent?: StrategicIntentResponseDto;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponseDto
     */
    status: ApplicationResponseDtoStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponseDto
     */
    validApp?: boolean;
    /**
     * 
     * @type {PlatformMetaResponseDto}
     * @memberof ApplicationResponseDto
     */
    platform: PlatformMetaResponseDto;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponseDto
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponseDto
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponseDto
     */
    modifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationResponseDto
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponseDto
     */
    canEdit?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationResponseDto
     */
    rejectedComments?: string;
    /**
     * 
     * @type {InformationClassificationResponseDto}
     * @memberof ApplicationResponseDto
     */
    classification?: InformationClassificationResponseDto;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationResponseDto
     */
    verified?: boolean;
    /**
     * 
     * @type {Set<LabelResponseDto>}
     * @memberof ApplicationResponseDto
     */
    labels?: Set<LabelResponseDto>;
}


/**
 * @export
 */
export const ApplicationResponseDtoStatusEnum = {
    Active: 'Active',
    Inactive: 'Inactive',
    Rejected: 'Rejected',
    Deleted: 'Deleted'
} as const;
export type ApplicationResponseDtoStatusEnum = typeof ApplicationResponseDtoStatusEnum[keyof typeof ApplicationResponseDtoStatusEnum];


/**
 * Check if a given object implements the ApplicationResponseDto interface.
 */
export function instanceOfApplicationResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "applicationId" in value;
    isInstance = isInstance && "applicationName" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "platform" in value;

    return isInstance;
}

export function ApplicationResponseDtoFromJSON(json: any): ApplicationResponseDto {
    return ApplicationResponseDtoFromJSONTyped(json, false);
}

export function ApplicationResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationId': json['applicationId'],
        'applicationName': json['applicationName'],
        'description': json['description'],
        'owners': !exists(json, 'owners') ? undefined : (new Set((json['owners'] as Array<any>).map(OwnerDtoFromJSON))),
        'targetDecommissioningDate': !exists(json, 'targetDecommissioningDate') ? undefined : (new Date(json['targetDecommissioningDate'])),
        'decommissionedDate': !exists(json, 'decommissionedDate') ? undefined : (new Date(json['decommissionedDate'])),
        'locallyManaged': !exists(json, 'locallyManaged') ? undefined : json['locallyManaged'],
        'criticalityCategory': !exists(json, 'criticalityCategory') ? undefined : MvcCategoryResponseDtoFromJSON(json['criticalityCategory']),
        'mipLayer': !exists(json, 'mipLayer') ? undefined : MipLayerResponseDtoFromJSON(json['mipLayer']),
        'strategicIntent': !exists(json, 'strategicIntent') ? undefined : StrategicIntentResponseDtoFromJSON(json['strategicIntent']),
        'status': json['status'],
        'validApp': !exists(json, 'validApp') ? undefined : json['validApp'],
        'platform': PlatformMetaResponseDtoFromJSON(json['platform']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (new Date(json['modifiedDate'])),
        'canEdit': !exists(json, 'canEdit') ? undefined : json['canEdit'],
        'rejectedComments': !exists(json, 'rejectedComments') ? undefined : json['rejectedComments'],
        'classification': !exists(json, 'classification') ? undefined : InformationClassificationResponseDtoFromJSON(json['classification']),
        'verified': !exists(json, 'verified') ? undefined : json['verified'],
        'labels': !exists(json, 'labels') ? undefined : (new Set((json['labels'] as Array<any>).map(LabelResponseDtoFromJSON))),
    };
}

export function ApplicationResponseDtoToJSON(value?: ApplicationResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationId': value.applicationId,
        'applicationName': value.applicationName,
        'description': value.description,
        'owners': value.owners === undefined ? undefined : (Array.from(value.owners as Set<any>).map(OwnerDtoToJSON)),
        'targetDecommissioningDate': value.targetDecommissioningDate === undefined ? undefined : (value.targetDecommissioningDate.toISOString().substring(0,10)),
        'decommissionedDate': value.decommissionedDate === undefined ? undefined : (value.decommissionedDate.toISOString().substring(0,10)),
        'locallyManaged': value.locallyManaged,
        'criticalityCategory': MvcCategoryResponseDtoToJSON(value.criticalityCategory),
        'mipLayer': MipLayerResponseDtoToJSON(value.mipLayer),
        'strategicIntent': StrategicIntentResponseDtoToJSON(value.strategicIntent),
        'status': value.status,
        'validApp': value.validApp,
        'platform': PlatformMetaResponseDtoToJSON(value.platform),
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate.toISOString()),
        'canEdit': value.canEdit,
        'rejectedComments': value.rejectedComments,
        'classification': InformationClassificationResponseDtoToJSON(value.classification),
        'verified': value.verified,
        'labels': value.labels === undefined ? undefined : (Array.from(value.labels as Set<any>).map(LabelResponseDtoToJSON)),
    };
}

