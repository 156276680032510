import classes from './Footer.module.scss';
import maerskLogoNegative from '@/assets/maersk-logo-negative.svg';
import { footer as text } from '@text';
import { getTextMessage } from '@/helpers/text';

function Footer() {
  return (
    <footer className={classes.footer}>
      <div>
        <img
          src={maerskLogoNegative}
          alt={''}
          height={32}
          className={classes.logo}
        />
        <div>
          {getTextMessage(text.copyright, {
            year: new Date(Date.now()).getFullYear(),
          })}
        </div>
      </div>
      <div className={classes.links}>
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://insightshub.maersk-digital.net/feature-suggestion/shared/87bb400f-9e3d-4488-9626-5ad4f27ffbc4/create"
          >
            {text.leaveFeedback}
          </a>
        </div>
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://mdp.maersk.io/docs/introduction/MDP-Roadmap"
          >
            {text.roadmap}
          </a>
        </div>
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://mdp.maersk.io/docs/introduction/MDP-Release-Notes"
          >
            {text.releaseNotes}
          </a>
        </div>
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://mdp.maersk.io/docs/introduction/support/"
          >
            {text.support}
          </a>
        </div>
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://teams.microsoft.com/l/channel/19%3Afe6cf0c19fa44c0ea23670c9285bd8e9%40thread.tacv2/Customers%20support?groupId=2d7e5d34-4e62-48a4-8639-a0fa92b0880c&tenantId=05d75c05-fa1a-42e7-9cf1-eb416c396f2d"
          >
            {text.contact}
          </a>
        </div>
        <div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/maersk-Global/mdp"
          >
            {text.github}
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
