import { PipelineStatusV1StepsInnerDto } from '@@/generated/openapi';
import {
  quickstartSteps,
  genericPipelineStepMessages,
} from '@@/generated/text/text.json';
import { parseTemplateString } from './utils';

type QuickstartStepTextType = {
  [key: string]: {
    name: string;
    description: string;
    message?: {
      [key: string]: string;
    };
  };
};

type GenericPipelineStepMessageType = {
  [key: string]: {
    name: string;
    description: string;
    message: string;
  };
};

const quickstartStepsType = quickstartSteps as QuickstartStepTextType;
const genericPipelineStepsTyped =
  genericPipelineStepMessages as GenericPipelineStepMessageType;

export const getStepText = (step?: PipelineStatusV1StepsInnerDto) => {
  const result =
    quickstartSteps[
      (step?.code ?? 'Completed') as keyof typeof quickstartSteps
    ] ?? quickstartSteps['Unknown'];
  return result;
};

export const getStepMessage = (step?: PipelineStatusV1StepsInnerDto) => {
  const quickstartText =
    quickstartStepsType[step?.code ?? 'Completed'] ??
    quickstartSteps['Unknown'];

  const code = step?.message?.code ?? '';
  const context = step?.message?.context ?? {};

  if (quickstartText && quickstartText.message?.[code]) {
    return (
      parseTemplateString(quickstartText.message[code] ?? '', context) || ''
    );
  }

  if (step?.message?.code && genericPipelineStepsTyped[step?.message?.code]) {
    return (
      parseTemplateString(genericPipelineStepsTyped[code].message, context) ||
      ''
    );
  }
  return '';
};
