import { FormInputGroup } from '@/components/FormInputGroup/FormInputGroup';
import FormWrapper from '@/components/FormWrapper/FormWrapper';
import Input from '@/components/Input/Input';
import Select from '@/components/Select/Select';
import { eventsProcessingPage } from '@text';
import {
  EnvironmentClassV1Dto,
  SchemaTypeV1Dto,
  SchemaV1Dto,
} from '@@/generated/openapi';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import CodeInput from '@/components/CodeInput/CodeInput';
import { useToast } from '@/providers/ToastProvider';
import { lowercaseOnly } from '@/helpers/regex';
import { yupResolver } from '@hookform/resolvers/yup';

interface SchemaFormProps {
  onSubmit: (data: SchemaV1Dto) => Promise<void>;
  initialValues?: SchemaV1Dto;
  mode?: 'create' | 'edit';
}

const schema = yup.object({
  name: yup
    .string()
    .required('Please provide a schema name')
    .matches(lowercaseOnly, 'Schema name can only contain lowercase letters'),
  type: yup.string().required('Please select a schema type'),
  content: yup
    .string()
    .required('Please provide a schema content')
    .test('is-json', 'Content must be valid JSON', (value) => {
      if (!value) return false;
      try {
        JSON.parse(value);
        return true;
      } catch (error) {
        return false;
      }
    }),
});

export const SchemaForm = ({
  onSubmit,
  mode = 'create',
  initialValues,
}: SchemaFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const form = useForm<SchemaV1Dto>({
    // @ts-expect-error yupResolver here is correct, spec is optional, but we want name
    resolver: yupResolver(schema),
    defaultValues: {
      environmentClass: EnvironmentClassV1Dto.Nonprod,
      applicationCode: params.app ?? '',
      name: '',
      type: 'AVRO',
      content: '',
      ...initialValues,
    },
  });

  const { showToast } = useToast();
  const userBelongsToAppTeam = useUserBelongsToAppTeam();

  const handleSubmit = async (data: SchemaV1Dto) => {
    try {
      setIsLoading(true);
      await onSubmit(data);
    } catch (error) {
      showToast({
        message: 'Error updating schema',
        appearance: 'error',
      });
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormWrapper form={form} schema={schema}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FormInputGroup width="normal">
          {mode === 'create' && (
            <Input
              disabled={isLoading}
              name="name"
              text={eventsProcessingPage.schemaForm.name}
            />
          )}
          <Select
            disabled={isLoading}
            name="type"
            valueOnly
            readonly={mode === 'edit'}
            text={eventsProcessingPage.schemaForm.type}
            options={Object.values(SchemaTypeV1Dto).map((type) => ({
              label: type,
              value: type,
            }))}
          />
          <CodeInput
            showCopy
            // showFormatter
            // formatter={(value) => JSON.stringify(JSON.parse(value), null, 2)}
            name="content"
            text={eventsProcessingPage.schemaForm.content}
            language="json"
            readonly={isLoading}
          />
          <div>
            <McButton
              type="submit"
              loading={isLoading}
              disabled={!userBelongsToAppTeam}
            >
              {eventsProcessingPage.schemaForm.submit[mode]}
            </McButton>
          </div>
        </FormInputGroup>
      </form>
    </FormWrapper>
  );
};
