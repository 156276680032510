import { Diff, DiffEdit } from 'deep-diff';
import classes from './DiffVisualization.module.scss';
import clsx from 'classnames';

interface DeepDiffItemProps {
  changeItem: Diff<unknown, unknown>;
}

const formatValue = (value: unknown): string => {
  if (value === undefined) return 'undefined';
  if (value === null) return 'null';
  if (value instanceof Set) {
    return `Set(${JSON.stringify(Array.from(value), null, 2)})`;
  }
  if (typeof value === 'object') {
    const processed = Object.entries(value as Record<string, unknown>).reduce(
      (acc, [key, val]) => {
        acc[key] = val instanceof Set ? Array.from(val) : val;
        return acc;
      },
      {} as Record<string, unknown>,
    );
    return JSON.stringify(processed, null, 2);
  }
  return String(value);
};

const DeepDiffItem = ({ changeItem }: DeepDiffItemProps) => {
  if (!changeItem) return null;

  switch (changeItem.kind) {
    case 'E': {
      const edit = changeItem;
      return (
        <>
          <span className={clsx(classes.oldValue, classes.editValue)}>
            {formatValue(edit.lhs)}
          </span>
          <span className={classes.arrow}>→</span>
          <span className={clsx(classes.newValue, classes.editValue)}>
            {formatValue(edit.rhs)}
          </span>
        </>
      );
    }
    case 'N': {
      const newItem = changeItem;
      return (
        <span className={classes.newValue}>{formatValue(newItem.rhs)}</span>
      );
    }
    case 'D': {
      const deletedItem = changeItem;
      return (
        <span className={classes.oldValue}>{formatValue(deletedItem.lhs)}</span>
      );
    }
    case 'A': {
      const arrayChange = changeItem;
      const value =
        (arrayChange.item as DiffEdit<unknown>).lhs ||
        (arrayChange.item as DiffEdit<unknown>).rhs;
      return (
        <span
          className={
            (arrayChange.item as DiffEdit<unknown>).lhs
              ? classes.oldValue
              : classes.newValue
          }
        >
          {formatValue(value)}
        </span>
      );
    }
    default:
      return null;
  }
};

export default DeepDiffItem;
