import { ReactNode, useContext, useEffect } from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-powershell';
import 'prismjs/components/prism-properties';
import 'prismjs/themes/prism.min.css';
// import 'prismjs/themes/prism-dark.css';
import { ThemeContext } from '@/providers/ThemeProvider';
import classes from './CodeEditor.module.scss';

interface CodeEditorProps {
  value: string;
  onChange?: (value: string) => void;
  readonly?: boolean;
  text?: {
    label?: string;
    placeholder?: string;
    hint?: string;
  };
  language?: string;
  actions?: ReactNode;
  error?: string;
}

function CodeEditor({
  value,
  onChange,
  text,
  language = 'json',
  readonly,
  actions,
  error,
}: CodeEditorProps) {
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    document.documentElement.classList.remove('prism-light', 'prism-dark');
    const editorElement = document.querySelector(`.${classes.wrapper}`);
    if (editorElement) {
      editorElement.classList.remove('prism-theme-light', 'prism-theme-dark');
      editorElement.classList.add(`prism-theme-${theme}`);
    }
  }, [theme]);

  const langSyntax = languages[language] || languages.json;

  return (
    <div>
      <div className={classes.header}>
        {text?.label && <label>{text.label}</label>}
        {actions && <div className={classes.actions}>{actions}</div>}
      </div>
      <div className={classes.wrapper}>
        <Editor
          value={value}
          onValueChange={onChange || (() => {})}
          highlight={(code) => highlight(code, langSyntax, language)}
          padding={10}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 14,
          }}
          readOnly={readonly}
          placeholder={text?.placeholder}
        />
      </div>
      {error && <span className={classes.error}>{error}</span>}
      {text?.hint && <span className={classes.hint}>{text.hint}</span>}
    </div>
  );
}

export default CodeEditor;
