/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AzureUserDto } from './AzureUserDto';
import {
    AzureUserDtoFromJSON,
    AzureUserDtoFromJSONTyped,
    AzureUserDtoToJSON,
} from './AzureUserDto';

/**
 * 
 * @export
 * @interface UserSearchResponseDto
 */
export interface UserSearchResponseDto {
    /**
     * 
     * @type {Array<AzureUserDto>}
     * @memberof UserSearchResponseDto
     */
    users?: Array<AzureUserDto>;
}

/**
 * Check if a given object implements the UserSearchResponseDto interface.
 */
export function instanceOfUserSearchResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserSearchResponseDtoFromJSON(json: any): UserSearchResponseDto {
    return UserSearchResponseDtoFromJSONTyped(json, false);
}

export function UserSearchResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSearchResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'users': !exists(json, 'users') ? undefined : ((json['users'] as Array<any>).map(AzureUserDtoFromJSON)),
    };
}

export function UserSearchResponseDtoToJSON(value?: UserSearchResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'users': value.users === undefined ? undefined : ((value.users as Array<any>).map(AzureUserDtoToJSON)),
    };
}

