import useSWR from 'swr';
import { DefaultApi } from '@@/generated/openapi';
import { CustomSwrHook } from '@/interfaces/swr';
import { normalizeKey } from '@/helpers/swr';

export const useKafkaSchemas = ({
  key,
  args,
  config,
  pause,
  onlyReadyToUse,
}: CustomSwrHook<DefaultApi['getSchemasV1']> & {
  onlyReadyToUse?: boolean;
} = {}) => {
  const client = useApiClient();
  const params = useParams();

  const normalizedKey = normalizeKey({
    key,
    pause,
    defaultKey: ['kafka-schemas', params?.app, params?.env, onlyReadyToUse],
  });
  const normalizedArgs = args ?? [
    params?.app ?? '',
    params?.env ?? '',
    onlyReadyToUse,
  ];

  return useSWR(
    normalizedKey,
    () => client.getSchemasV1(...normalizedArgs),
    config,
  );
};
