import {
  PipelineStatusV1Dto,
  PipelineStatusV1StepsInnerDtoStateEnum,
  ProblemDetailDto,
} from '@@/generated/openapi';
import { parseTemplateString } from '@/helpers/utils';
import { defaultErrorMessages } from '@@/generated/text/text.json';
import { getStepMessage, getStepText } from './quickstart';
import { AllPipelineType } from '@/components/CurrentPipelineStep/CurrentPipelineStep';

export const isProblemDetail = (error: unknown): error is ProblemDetailDto => {
  if (!error) {
    return false;
  }
  return (error as ProblemDetailDto).traceId !== undefined;
};

export const flattenErrors = (
  obj: Record<string, unknown>,
  prefix?: string,
  result: Record<string, unknown> = {},
) => {
  if (prefix && Object.prototype.hasOwnProperty.call(obj, 'ref')) {
    result[prefix] = obj;
    return result;
  }

  const newPrefix = prefix ? `${prefix}.` : '';

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (
        typeof obj[key] === 'object' &&
        obj[key] !== null &&
        (Array.isArray(obj[key]) ||
          Object.prototype.toString.call(obj[key]) === '[object Object]')
      ) {
        flattenErrors(
          obj[key] as Record<string, unknown>,
          `${newPrefix}${key}`,
          result,
        );
      } else {
        result[`${newPrefix}${key}`] = obj[key];
      }
    }
  }

  return result;
};

export const hasErrorMap = (error: unknown) => {
  return isProblemDetail(error) && !!error.errors && error.errors.length > 0;
};

export const getErrorMessage = (
  code: string,
  text: Record<string, string>,
  context: object = {},
) => {
  if (text[code]) {
    return parseTemplateString(text[code], context);
  }

  const errorTemplate =
    defaultErrorMessages[code as keyof typeof defaultErrorMessages];

  if (errorTemplate) {
    return parseTemplateString(errorTemplate, context);
  }

  return code;
};

export class PipelineError extends Error {
  readonly detail?: string;

  constructor(pipelineResponse: AllPipelineType | PipelineStatusV1Dto) {
    if ('status' in pipelineResponse) {
      const currentStep =
        pipelineResponse.status?.steps.find(
          (step) =>
            step.state === PipelineStatusV1StepsInnerDtoStateEnum.Running,
        ) ||
        pipelineResponse.status?.steps.find(
          (step) =>
            step.state === PipelineStatusV1StepsInnerDtoStateEnum.Failed,
        );
      const message = `Pipeline step "${getStepText(currentStep).name}" failed`;

      super(message);
      this.detail = getStepMessage(currentStep);
      this.name = message;
    } else {
      super('Pipeline failed');
    }
  }
}
