import { Suspense } from 'react';
import DefaultLayout from '@/layouts/Default/Default';
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary';
import { LoaderFunctionArgs } from 'react-router-dom';
import { EventProcessingSchemaPage } from './pages/EventsProcessing/EventProcessingSchemaPage';
import { EventsProcessingCreateSchemaPage } from './pages/EventsProcessing/EventsProcessingCreateSchemaPage';
import EventsProcessingCreateTopicPage from './pages/EventsProcessing/EventsProcessingCreateTopicPage';
import { EventsProcessingCreateAccountPage } from '@/pages/EventsProcessing/EventsProcessingCreateAccountPage';

const AppDashboardPage = lazy(
  () => import('@/pages/AppDashboard/AppDashboard'),
);
const AppPage = lazy(() => import('@/pages/App/App'));
const ServicePage = lazy(() => import('@/pages/Service/Service'));
const DatabasePage = lazy(() => import('@/pages/Database/Database'));
const NotFoundPage = lazy(() => import('@/pages/NotFound/NotFound'));
const RevisionPage = lazy(() => import('@/pages/Revision/Revision'));
const PipelinePage = lazy(() => import('@/pages/Pipeline/Pipeline'));
const TeamsPage = lazy(() => import('@/pages/Teams/Teams'));
const TeamPage = lazy(() => import('@/pages/Team/Team'));
const NewSecretGroupPage = lazy(
  () => import('@/pages/NewSecretGroup/NewSecretGroup'),
);
const SecretGroupPage = lazy(() => import('@/pages/SecretGroup/SecretGroup'));
const NewBlankServicePage = lazy(
  () => import('@/pages/NewBlankService/NewBlankService'),
);
const CopyServicePage = lazy(() => import('@/pages/CopyService/CopyService'));
const CopySecretGroupPage = lazy(
  () => import('@/pages/CopySecretGroup/CopySecretGroup'),
);
const EventsProcessingTopicPage = lazy(
  () => import('@/pages/EventsProcessing/EventsProcessingTopicPage'),
);

const EventProcessingAccountPage = lazy(
  () => import('@/pages/EventsProcessing/EventProcessingAccountPage'),
);

export default [
  {
    path: '/',
    element: <DefaultLayout />,
    errorElement: (
      <DefaultLayout>
        <ErrorBoundary />
      </DefaultLayout>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense>
            <AppDashboardPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'my-apps',
          };
        },
      },
      {
        path: '/my-apps',
        element: (
          <Suspense>
            <AppDashboardPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'my-apps',
          };
        },
      },
      {
        path: '/all-apps',
        element: (
          <Suspense>
            <AppDashboardPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'all-apps',
          };
        },
      },
      {
        path: '/team',
        element: (
          <Suspense>
            <TeamsPage />
          </Suspense>
        ),
        loader: () => {
          return { tab: 'my-teams' };
        },
      },
      {
        path: '/my-teams',
        element: (
          <Suspense>
            <TeamsPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'my-teams',
          };
        },
      },
      {
        path: '/all-teams',
        element: (
          <Suspense>
            <TeamsPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'all-teams',
          };
        },
      },
      {
        path: 'team/:team',
        element: (
          <Suspense>
            <TeamPage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/settings',
        element: (
          <Suspense>
            <AppPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'secret-group',
            subtab: 'settings',
          };
        },
      },
      {
        path: 'app/:app/:env/service/:service/revision/:revision',
        element: (
          <Suspense>
            <RevisionPage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/service/:service/pipeline/:pipeline',
        element: (
          <Suspense>
            <PipelinePage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/service/:service/:tab',
        element: (
          <Suspense>
            <ServicePage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/service/:service/copy-service',
        element: (
          <Suspense>
            <CopyServicePage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/service/:service/',
        element: (
          <Suspense>
            <ServicePage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/database/:database/',
        element: (
          <Suspense>
            <DatabasePage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/database',
        element: (
          <Suspense>
            <AppPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'database',
          };
        },
      },
      {
        path: 'app/:app/:env/events-processing/create-schema',
        element: (
          <Suspense>
            <EventsProcessingCreateSchemaPage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/events-processing/schemas/:schema',
        element: (
          <Suspense>
            <EventProcessingSchemaPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'events-processing-topics',
          };
        },
      },
      {
        path: 'app/:app/:env/events-processing/accounts/:account',
        element: (
          <Suspense>
            <EventProcessingAccountPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'events-processing-topics',
          };
        },
      },
      {
        path: 'app/:app/:env/events-processing/create-topic',
        element: (
          <Suspense>
            <EventsProcessingCreateTopicPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'events-processing-topics',
          };
        },
      },
      {
        path: 'app/:app/:env/events-processing/topics/:topic',
        element: (
          <Suspense>
            <EventsProcessingTopicPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'events-processing-topics',
          };
        },
      },
      {
        path: 'app/:app/:env/events-processing/:subtab?',
        element: (
          <Suspense>
            <AppPage />
          </Suspense>
        ),
        loader: ({ params }: LoaderFunctionArgs) => {
          return {
            tab: 'events-processing',
            subtab: params.subtab,
          };
        },
      },
      {
        path: 'app/:app/:env/container-registry',
        element: (
          <Suspense>
            <AppPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'container-registry',
          };
        },
      },
      {
        path: 'app/:app/:env/service',
        element: (
          <Suspense>
            <AppPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'service',
          };
        },
      },
      {
        path: 'app/:app/:env/secret-group/:secretGroup',
        element: (
          <Suspense>
            <SecretGroupPage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/secret-group/:secretGroup/copy',
        element: (
          <Suspense>
            <CopySecretGroupPage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/secret-group',
        element: (
          <Suspense>
            <AppPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'secret-group',
          };
        },
      },
      {
        path: 'app/:app/:env/new-secret-group',
        element: (
          <Suspense>
            <NewSecretGroupPage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/new-service',
        element: (
          <Suspense>
            <NewBlankServicePage />
          </Suspense>
        ),
      },
      {
        path: 'app/:app/:env/activity-logs',
        element: (
          <Suspense>
            <AppPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'activity-logs',
          };
        },
      },
      {
        path: 'app/:app/:env',
        element: (
          <Suspense>
            <AppPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'service',
          };
        },
      },
      {
        path: 'app/:app',
        element: (
          <Suspense>
            <AppPage />
          </Suspense>
        ),
        loader: () => {
          return {
            tab: 'service',
          };
        },
      },
      {
        path: 'app/:app/:env/events-processing/accounts/new',
        element: <EventsProcessingCreateAccountPage />,
      },
      {
        path: '*',
        element: (
          <Suspense>
            <NotFoundPage />
          </Suspense>
        ),
      },
    ],
  },
];
