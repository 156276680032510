import { useNavigate, useBlocker, Location, To } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';

export const useNavigationBlocker = (isDirty: boolean) => {
  const navigate = useNavigate();
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const [blockerEnabled, setBlockerEnabled] = useState(isDirty);

  useEffect(() => {
    setBlockerEnabled(isDirty);
  }, [isDirty]);

  const handleNavigationConfirm = useCallback(async () => {
    if (nextLocation) {
      setBlockerEnabled(false);
      await new Promise((resolve) => setTimeout(resolve, 0));
      navigate(nextLocation.pathname as To);
      setNextLocation(null);
    }
  }, [nextLocation, navigate]);

  const handleNavigationCancel = useCallback(() => {
    setNextLocation(null);
  }, []);

  useBlocker(
    useCallback(
      ({ nextLocation: next }: { nextLocation: Location }) => {
        if (blockerEnabled && isDirty) {
          setNextLocation(next);
          return true;
        }
        return false;
      },
      [blockerEnabled, isDirty],
    ),
  );

  return {
    nextLocation,
    handleNavigationConfirm,
    handleNavigationCancel,
  };
};
