/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';

/**
 * 
 * @export
 * @interface NameIdDto
 */
export interface NameIdDto {
    /**
     * 
     * @type {string}
     * @memberof NameIdDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof NameIdDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof NameIdDto
     */
    source?: NameIdDtoSourceEnum;
    /**
     * 
     * @type {Set<OwnerDto>}
     * @memberof NameIdDto
     */
    owners?: Set<OwnerDto>;
    /**
     * 
     * @type {number}
     * @memberof NameIdDto
     */
    score?: number;
}


/**
 * @export
 */
export const NameIdDtoSourceEnum = {
    Mac: 'MAC',
    Eac: 'EAC',
    Snow: 'SNOW',
    Admiral: 'ADMIRAL',
    Mdp: 'MDP'
} as const;
export type NameIdDtoSourceEnum = typeof NameIdDtoSourceEnum[keyof typeof NameIdDtoSourceEnum];


/**
 * Check if a given object implements the NameIdDto interface.
 */
export function instanceOfNameIdDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NameIdDtoFromJSON(json: any): NameIdDto {
    return NameIdDtoFromJSONTyped(json, false);
}

export function NameIdDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameIdDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'owners': !exists(json, 'owners') ? undefined : (new Set((json['owners'] as Array<any>).map(OwnerDtoFromJSON))),
        'score': !exists(json, 'score') ? undefined : json['score'],
    };
}

export function NameIdDtoToJSON(value?: NameIdDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'id': value.id,
        'source': value.source,
        'owners': value.owners === undefined ? undefined : (Array.from(value.owners as Set<any>).map(OwnerDtoToJSON)),
        'score': value.score,
    };
}

