import { parseTemplateString } from '@/helpers/utils';
import { serviceSettings, regions } from '@text';

export const getTextMessage = (
  message: string,
  context: Record<string, unknown>,
) => {
  return parseTemplateString(message, context);
};

export function renderSettingsLabel(path?: string[], includeFullName = true) {
  if (!path || path.length === 0) {
    return '';
  }

  // Handle array indices by removing them from the lookup path
  const cleanPath = path.map((segment) => {
    // Check if the segment is a number or matches array index pattern
    return /^\d+$/.test(segment) ? '*' : segment;
  });
  const name = path.join('.'); // Keep original path for display
  const lookupName = cleanPath.join('.'); // Use clean path for lookup

  // Try to find exact match first
  const exactKey = Object.keys(serviceSettings).find((key) => {
    const fieldName = key.replace('.template', '');
    return (
      lookupName === fieldName || lookupName.replace(/\.\*/g, '') === fieldName
    );
  }) as keyof typeof serviceSettings;

  if (exactKey) {
    const field = serviceSettings[exactKey];
    if ('fullName' in field && includeFullName) {
      return field.fullName;
    }
    if ('label' in field) {
      return field.label;
    }
  }

  // If no exact match, try to find the most specific parent key
  const parentKeys = Object.keys(serviceSettings)
    .filter((key) => {
      const fieldName = key.replace('.template', '');
      return (
        lookupName.startsWith(fieldName + '.') ||
        lookupName.replace(/\.\*/g, '').startsWith(fieldName + '.')
      );
    })
    .sort((a, b) => b.length - a.length); // Sort by length descending to get most specific first

  if (parentKeys.length > 0) {
    const mostSpecificKey = parentKeys[0] as keyof typeof serviceSettings;
    const field = serviceSettings[mostSpecificKey];
    if ('label' in field) {
      return field.label;
    }
    if ('fullName' in field && includeFullName) {
      return field.fullName;
    }
  }

  // If no match found, return the full path
  return name;
}

export const getRegion = (region: string): string => {
  return regions[region.toUpperCase() as keyof typeof regions].label || region;
};
