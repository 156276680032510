import { useFormContext } from 'react-hook-form';
import Markdown from '@/components/Markdown/Markdown';
import ReadOnlyInput from '@/components/Input/ReadOnlyInput/ReadOnlyInput';
import { useFieldError } from '@/hooks/useFieldError';
import classes from './InputStepper.module.scss';
import { InputStepperProps } from '@/interfaces/fields';
import { McNumberStepper } from '@maersk-global/mds-react-wrapper/components-core/mc-number-stepper';
import { useFormReadonly } from '../FormWrapper/components/FormReadonlyProvider';

const InputStepper = ({
  name,
  text,
  prefix,
  suffix,
  serverNames,
  hiddenlabel,
  readonly,
  width,
  autocomplete,
  min,
  max,
  step,
  disabled,
  ...rest
}: InputStepperProps) => {
  const { register, getValues, setValue } = useFormContext();
  const value = getValues(name);
  const error = useFieldError(name, text, serverNames);
  const { readonly: formReadonly } = useFormReadonly();
  const isReadonly = readonly || formReadonly;
  const inputProps = register(name, {
    valueAsNumber: true,
    onChange: (e) => {
      const newValue = Number(e.target.value);
      setValue(name, newValue);
    },
  });

  if (isReadonly) {
    return (
      <ReadOnlyInput
        value={value}
        name={name}
        text={text}
        hiddenlabel={hiddenlabel}
        {...rest}
      />
    );
  }

  return (
    <label>
      <div className={classes.inputCont}>
        <McNumberStepper
          prefix={prefix}
          suffix={suffix}
          placeholder={text?.placeholder}
          min={min}
          max={max}
          style={{ width: `${width}%` }}
          step={step}
          label={text?.label}
          data-testid={name}
          hiddenlabel={hiddenlabel}
          disabled={disabled}
          autocomplete={autocomplete}
          input={inputProps.onChange}
          {...inputProps}
        />
      </div>
      {!!error && <div className={classes.error}>{error}</div>}
      {text?.hint && (
        <Markdown isHint={true} className={classes.hint}>
          {text?.hint}
        </Markdown>
      )}
    </label>
  );
};

export default InputStepper;
