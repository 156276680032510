/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApplicationSummaryResponseDto } from './ApplicationSummaryResponseDto';
import {
    ApplicationSummaryResponseDtoFromJSON,
    ApplicationSummaryResponseDtoFromJSONTyped,
    ApplicationSummaryResponseDtoToJSON,
} from './ApplicationSummaryResponseDto';

/**
 * 
 * @export
 * @interface PlatformSummaryDto
 */
export interface PlatformSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof PlatformSummaryDto
     */
    platformId?: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformSummaryDto
     */
    platformName?: string;
    /**
     * 
     * @type {number}
     * @memberof PlatformSummaryDto
     */
    onboarded?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformSummaryDto
     */
    active?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformSummaryDto
     */
    deleted?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformSummaryDto
     */
    rejected?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformSummaryDto
     */
    verified?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformSummaryDto
     */
    unverified?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformSummaryDto
     */
    changeRequest?: number;
    /**
     * 
     * @type {number}
     * @memberof PlatformSummaryDto
     */
    dataIssue?: number;
    /**
     * 
     * @type {string}
     * @memberof PlatformSummaryDto
     */
    appOnboardTime?: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformSummaryDto
     */
    platformChangeTime?: string;
    /**
     * 
     * @type {string}
     * @memberof PlatformSummaryDto
     */
    dataIssueTime?: string;
    /**
     * 
     * @type {Array<ApplicationSummaryResponseDto>}
     * @memberof PlatformSummaryDto
     */
    applicationsOnboarded?: Array<ApplicationSummaryResponseDto>;
    /**
     * 
     * @type {Array<ApplicationSummaryResponseDto>}
     * @memberof PlatformSummaryDto
     */
    platformChangeRequests?: Array<ApplicationSummaryResponseDto>;
    /**
     * 
     * @type {Array<ApplicationSummaryResponseDto>}
     * @memberof PlatformSummaryDto
     */
    reportedDataIssues?: Array<ApplicationSummaryResponseDto>;
    /**
     * 
     * @type {Array<ApplicationSummaryResponseDto>}
     * @memberof PlatformSummaryDto
     */
    unverifiedApplications?: Array<ApplicationSummaryResponseDto>;
}

/**
 * Check if a given object implements the PlatformSummaryDto interface.
 */
export function instanceOfPlatformSummaryDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PlatformSummaryDtoFromJSON(json: any): PlatformSummaryDto {
    return PlatformSummaryDtoFromJSONTyped(json, false);
}

export function PlatformSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlatformSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'platformId': !exists(json, 'platformId') ? undefined : json['platformId'],
        'platformName': !exists(json, 'platformName') ? undefined : json['platformName'],
        'onboarded': !exists(json, 'onboarded') ? undefined : json['onboarded'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'deleted': !exists(json, 'deleted') ? undefined : json['deleted'],
        'rejected': !exists(json, 'rejected') ? undefined : json['rejected'],
        'verified': !exists(json, 'verified') ? undefined : json['verified'],
        'unverified': !exists(json, 'unverified') ? undefined : json['unverified'],
        'changeRequest': !exists(json, 'changeRequest') ? undefined : json['changeRequest'],
        'dataIssue': !exists(json, 'dataIssue') ? undefined : json['dataIssue'],
        'appOnboardTime': !exists(json, 'appOnboardTime') ? undefined : json['appOnboardTime'],
        'platformChangeTime': !exists(json, 'platformChangeTime') ? undefined : json['platformChangeTime'],
        'dataIssueTime': !exists(json, 'dataIssueTime') ? undefined : json['dataIssueTime'],
        'applicationsOnboarded': !exists(json, 'applicationsOnboarded') ? undefined : ((json['applicationsOnboarded'] as Array<any>).map(ApplicationSummaryResponseDtoFromJSON)),
        'platformChangeRequests': !exists(json, 'platformChangeRequests') ? undefined : ((json['platformChangeRequests'] as Array<any>).map(ApplicationSummaryResponseDtoFromJSON)),
        'reportedDataIssues': !exists(json, 'reportedDataIssues') ? undefined : ((json['reportedDataIssues'] as Array<any>).map(ApplicationSummaryResponseDtoFromJSON)),
        'unverifiedApplications': !exists(json, 'unverifiedApplications') ? undefined : ((json['unverifiedApplications'] as Array<any>).map(ApplicationSummaryResponseDtoFromJSON)),
    };
}

export function PlatformSummaryDtoToJSON(value?: PlatformSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'platformId': value.platformId,
        'platformName': value.platformName,
        'onboarded': value.onboarded,
        'active': value.active,
        'deleted': value.deleted,
        'rejected': value.rejected,
        'verified': value.verified,
        'unverified': value.unverified,
        'changeRequest': value.changeRequest,
        'dataIssue': value.dataIssue,
        'appOnboardTime': value.appOnboardTime,
        'platformChangeTime': value.platformChangeTime,
        'dataIssueTime': value.dataIssueTime,
        'applicationsOnboarded': value.applicationsOnboarded === undefined ? undefined : ((value.applicationsOnboarded as Array<any>).map(ApplicationSummaryResponseDtoToJSON)),
        'platformChangeRequests': value.platformChangeRequests === undefined ? undefined : ((value.platformChangeRequests as Array<any>).map(ApplicationSummaryResponseDtoToJSON)),
        'reportedDataIssues': value.reportedDataIssues === undefined ? undefined : ((value.reportedDataIssues as Array<any>).map(ApplicationSummaryResponseDtoToJSON)),
        'unverifiedApplications': value.unverifiedApplications === undefined ? undefined : ((value.unverifiedApplications as Array<any>).map(ApplicationSummaryResponseDtoToJSON)),
    };
}

