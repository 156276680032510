/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ApplicationRequestDto } from './ApplicationRequestDto';
import {
    instanceOfApplicationRequestDto,
    ApplicationRequestDtoFromJSON,
    ApplicationRequestDtoFromJSONTyped,
    ApplicationRequestDtoToJSON,
} from './ApplicationRequestDto';
import type { ApplicationRequestV2Dto } from './ApplicationRequestV2Dto';
import {
    instanceOfApplicationRequestV2Dto,
    ApplicationRequestV2DtoFromJSON,
    ApplicationRequestV2DtoFromJSONTyped,
    ApplicationRequestV2DtoToJSON,
} from './ApplicationRequestV2Dto';

/**
 * @type OnBoardApplicationRequestDto
 * 
 * @export
 */
export type OnBoardApplicationRequestDto = ApplicationRequestDto | ApplicationRequestV2Dto;

export function OnBoardApplicationRequestDtoFromJSON(json: any): OnBoardApplicationRequestDto {
    return OnBoardApplicationRequestDtoFromJSONTyped(json, false);
}

export function OnBoardApplicationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnBoardApplicationRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...ApplicationRequestDtoFromJSONTyped(json, true), ...ApplicationRequestV2DtoFromJSONTyped(json, true) };
}

export function OnBoardApplicationRequestDtoToJSON(value?: OnBoardApplicationRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfApplicationRequestDto(value)) {
        return ApplicationRequestDtoToJSON(value as ApplicationRequestDto);
    }
    if (instanceOfApplicationRequestV2Dto(value)) {
        return ApplicationRequestV2DtoToJSON(value as ApplicationRequestV2Dto);
    }

    return {};
}

