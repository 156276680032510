import { FormProvider, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import ValidationSchemaProvider from '@/providers/ValidationSchemaProvider';
import { FC } from 'react';
import React from 'react';
import { useState } from 'react';
import { useNavigationBlocker } from './hooks/useNavigationBlocker';
import { useBeforeUnloadHandler } from './hooks/useBeforeUnloadHandler';
import { ConfirmChangesModal } from './components/ConfirmChangesModal';
import { UnsavedChangesModal } from './components/UnsavedChangesModal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { FormReadonlyProvider } from './components/FormReadonlyProvider';

interface FormWrapperProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
  schema: yup.Schema;
  children: React.ReactElement<HTMLFormElement>;
  preventNavigationIfDirty?: boolean;
  initialData?: Record<string, unknown>;
  preventSubmit?: boolean;
  confirmChanges?: boolean;
  confirmEmpty?: boolean;
  readonly?: boolean;
}

/**
 * FormWrapper is a component that wraps a form and provides a confirmation modal when the form is submitted.
 * It also provides a confirmation modal when the form is submitted.
 * @param {FormWrapperProps} props - The props for the FormWrapper component.
 * @param {UseFormReturn<any, any>} props.form - The form to wrap.
 * @param {yup.Schema} props.schema - The schema for the form.
 * @param {boolean} props.preventNavigationIfDirty - Whether to prevent navigation if the form has been modified.
 * @param {boolean} props.confirmChanges - Whether to confirm changes and show diff with previous values.
 * @param {boolean} props.confirmEmpty - Whether to confirm creation or copy of an entity.
 * @param {boolean} props.readonly - Whether to make the form readonly.
 */

const FormWrapper: FC<FormWrapperProps> = ({
  form,
  schema,
  children,
  preventNavigationIfDirty = false,
  confirmChanges = false,
  confirmEmpty = false,
  initialData,
  readonly = false,
}: FormWrapperProps) => {
  const { isDirty, touchedFields } = form.formState;
  const shouldPreventNavigation =
    preventNavigationIfDirty &&
    (isDirty || Object.keys(touchedFields).length > 0);
  const { nextLocation, handleNavigationConfirm, handleNavigationCancel } =
    useNavigationBlocker(shouldPreventNavigation);
  const [showConfirmChanges, setShowConfirmChanges] = useState(false);

  useBeforeUnloadHandler(shouldPreventNavigation);

  // Add wrapper for the form element to prevent submission
  const wrappedChildren = React.cloneElement(children, {
    onSubmit: async (e: React.FormEvent) => {
      e.preventDefault();

      const isValid = await form.trigger();

      if (isValid && (confirmChanges || confirmEmpty)) {
        setShowConfirmChanges(true);
        return;
      }

      if (children.props.onSubmit) {
        await children.props.onSubmit(e);
      }
    },
  });

  const handleConfirmChanges = async () => {
    if (children.props.onSubmit) {
      await children.props.onSubmit();
    }
    setShowConfirmChanges(false);
  };

  const handleCancelChanges = () => {
    setShowConfirmChanges(false);
  };

  return (
    <ValidationSchemaProvider schema={schema}>
      {shouldPreventNavigation && (
        <UnsavedChangesModal
          isOpen={Boolean(nextLocation && shouldPreventNavigation)}
          onConfirm={handleNavigationConfirm}
          onCancel={handleNavigationCancel}
        />
      )}

      <FormProvider {...form}>
        <FormReadonlyProvider initialReadonly={readonly}>
          {confirmEmpty && (
            <ConfirmationModal
              open={showConfirmChanges}
              onSubmit={handleConfirmChanges}
              onClose={handleCancelChanges}
              title={'Confirm changes'}
              submitAppearance={'primary'}
              submitText={'Confirm'}
              text={<div>Are you sure you want to submit?</div>}
            />
          )}
          {confirmChanges && (
            <ConfirmChangesModal
              isOpen={showConfirmChanges}
              onConfirm={handleConfirmChanges}
              onCancel={handleCancelChanges}
              ignorePaths={[
                'spec.externalTraffic.revisionId',
                'spec.externalTraffic.resourceId',
                'spec.externalTraffic.externalTrafficType',
              ]}
              initialValues={initialData ?? {}}
            />
          )}
          {wrappedChildren}
        </FormReadonlyProvider>
      </FormProvider>
    </ValidationSchemaProvider>
  );
};

export default FormWrapper;
