import { ResourceRefV1Dto } from '@@/generated/openapi';
import { McOption } from '@maersk-global/mds-react-wrapper/components-core/mc-option';
import { McSelect } from '@maersk-global/mds-react-wrapper/components-core/mc-select';
import { useFormContext } from 'react-hook-form';
import { IMcSelectedOption } from '@maersk-global/mds-components-core';
import { getSchemaUrl } from '@/helpers/urls';
import classes from './SchemaSelect.module.scss';
import LinkButton, {
  LinkButtonFit,
  LinkButtonVariant,
} from '@/components/LinkButton/LinkButton';

export const SchemaSelect = ({
  name,
  disabled,
  text,
  mode,
  readonly,
}: {
  name: string;
  value?: ResourceRefV1Dto;
  disabled?: boolean;
  text?: {
    label: string;
    placeholder: string;
    hint: string;
  };
  mode: 'create' | 'edit';
  readonly?: boolean;
}) => {
  const { data: schemas } = useKafkaSchemas({
    onlyReadyToUse: true,
  });
  const params = useParams();

  const { setValue, getValues } = useFormContext();

  const error = useFieldError(name, text || { label: 'Schema' });

  const formValue = getValues(name);

  if (formValue && mode === 'edit') {
    return (
      <div className={classes.schemaSelectReadonly}>
        <div className={classes.schemaSelectReadonlyLabel}>{text?.label}</div>
        <div className={classes.schemaSelectReadonlyValue}>
          <span className={classes.schemaSelectReadonlyValueId}>
            {formValue.resourceId}
          </span>
          <LinkButton
            variant={LinkButtonVariant.Plain}
            fit={LinkButtonFit.Medium}
            to={getSchemaUrl({
              app: params.app,
              env: params.env,
              schema: formValue.resourceId,
            })}
          >
            Edit schema
          </LinkButton>
        </div>
        <div className={classes.schemaSelectReadonlyHint}>{text?.hint}</div>
      </div>
    );
  }

  return (
    <div>
      <McSelect
        name={name}
        optionselected={(e: CustomEvent<IMcSelectedOption>) => {
          setValue(name, e.detail.value);
        }}
        disabled={disabled}
        readonly={readonly}
        label={text?.label}
        hint={text?.hint}
        placeholder={text?.placeholder}
      >
        {schemas?.map((schema) => (
          <McOption
            key={schema.id}
            value={{
              resourceId: schema.id,
              resourceType: 'SCHEMA',
              applicationCode: schema.applicationCode,
            }}
            selected={formValue?.resourceId === schema.id}
            disabled={readonly}
            sublabel={schema.id}
          >
            {schema.name || schema.id}
          </McOption>
        ))}
      </McSelect>
      {error && <div className={classes.schemaSelectError}>{error}</div>}
    </div>
  );
};
