import { diff, Diff } from 'deep-diff';
import { useMemo } from 'react';
import classes from './DiffVisualization.module.scss';
import DeepDiffItem from './DeepDiffItem';
import { deepDiff } from '@text';
import { renderSettingsLabel } from '@/helpers/text';

interface DiffVisualizationProps<T> {
  left: T;
  right: T;
  ignorePaths?: string[];
  defaultIgnoredKeys?: string[];
  labelRenderer?: (path: string[]) => string;
}

type TranslatedObject = Record<string, unknown>;

const translateSetToArrayRecursive = (obj: unknown): TranslatedObject => {
  if (obj instanceof Set) {
    return Object.fromEntries(
      Array.from(obj).map((value, index) => [`${index}`, value]),
    );
  }
  if (typeof obj === 'object' && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj as Record<string, unknown>).map(([key, value]) => [
        key,
        translateSetToArrayRecursive(value),
      ]),
    );
  }
  return obj as TranslatedObject;
};

export const DiffVisualization = <T,>({
  left,
  right,
  ignorePaths = [],
  defaultIgnoredKeys = ['status', 'creationTimestamp'],
  labelRenderer = renderSettingsLabel,
}: DiffVisualizationProps<T>) => {
  const result = useMemo(() => {
    if (!left || !right) {
      return [];
    }
    // recursively translate all Set to Array
    const leftTranslated = translateSetToArrayRecursive(left);
    const rightTranslated = translateSetToArrayRecursive(right);
    const difference = diff(leftTranslated, rightTranslated, (path, key) => {
      return (
        defaultIgnoredKeys.includes(key as string) ||
        ignorePaths.includes(path.join('.')) ||
        ['spec.enabled', 'spec.secretGroupRefs'].includes(
          [...path, key].join('.'),
        )
      );
    });
    return difference;
  }, [left, right, ignorePaths, defaultIgnoredKeys]);

  if (!left || !right) {
    return null;
  }

  if (!result || result.length === 0) {
    return (
      <div className={classes.settingsContainer}>{deepDiff.noChanges}</div>
    );
  }

  return (
    <div className={classes.settingsContainer}>
      {result.map(
        (changeItem: Diff<TranslatedObject, TranslatedObject>, key: number) => (
          <div key={key} className={classes.itemDetails}>
            <div className={classes.label}>
              {labelRenderer(changeItem.path as string[])}
            </div>
            <div className={classes.item}>
              <DeepDiffItem changeItem={changeItem} />
            </div>
          </div>
        ),
      )}
    </div>
  );
};
