/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';

/**
 * 
 * @export
 * @interface ApplicationSummaryResponseDto
 */
export interface ApplicationSummaryResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationSummaryResponseDto
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSummaryResponseDto
     */
    applicationName?: string;
    /**
     * 
     * @type {OwnerDto}
     * @memberof ApplicationSummaryResponseDto
     */
    actionBy?: OwnerDto;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationSummaryResponseDto
     */
    actionDate?: Date;
    /**
     * 
     * @type {Set<OwnerDto>}
     * @memberof ApplicationSummaryResponseDto
     */
    owners?: Set<OwnerDto>;
    /**
     * 
     * @type {string}
     * @memberof ApplicationSummaryResponseDto
     */
    requestType?: string;
}

/**
 * Check if a given object implements the ApplicationSummaryResponseDto interface.
 */
export function instanceOfApplicationSummaryResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApplicationSummaryResponseDtoFromJSON(json: any): ApplicationSummaryResponseDto {
    return ApplicationSummaryResponseDtoFromJSONTyped(json, false);
}

export function ApplicationSummaryResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationSummaryResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'applicationName': !exists(json, 'applicationName') ? undefined : json['applicationName'],
        'actionBy': !exists(json, 'actionBy') ? undefined : OwnerDtoFromJSON(json['actionBy']),
        'actionDate': !exists(json, 'actionDate') ? undefined : (new Date(json['actionDate'])),
        'owners': !exists(json, 'owners') ? undefined : (new Set((json['owners'] as Array<any>).map(OwnerDtoFromJSON))),
        'requestType': !exists(json, 'requestType') ? undefined : json['requestType'],
    };
}

export function ApplicationSummaryResponseDtoToJSON(value?: ApplicationSummaryResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationId': value.applicationId,
        'applicationName': value.applicationName,
        'actionBy': OwnerDtoToJSON(value.actionBy),
        'actionDate': value.actionDate === undefined ? undefined : (value.actionDate.toISOString().substring(0,10)),
        'owners': value.owners === undefined ? undefined : (Array.from(value.owners as Set<any>).map(OwnerDtoToJSON)),
        'requestType': value.requestType,
    };
}

