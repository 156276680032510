/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Application's platform portfolio information
 * @export
 * @interface PortfolioMetaResponseDto
 */
export interface PortfolioMetaResponseDto {
    /**
     * Application's portfolio id
     * @type {string}
     * @memberof PortfolioMetaResponseDto
     */
    portfolioId: string;
    /**
     * Application's portfolio name
     * @type {string}
     * @memberof PortfolioMetaResponseDto
     */
    portfolioName: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioMetaResponseDto
     */
    portfolioType?: string;
}

/**
 * Check if a given object implements the PortfolioMetaResponseDto interface.
 */
export function instanceOfPortfolioMetaResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "portfolioId" in value;
    isInstance = isInstance && "portfolioName" in value;

    return isInstance;
}

export function PortfolioMetaResponseDtoFromJSON(json: any): PortfolioMetaResponseDto {
    return PortfolioMetaResponseDtoFromJSONTyped(json, false);
}

export function PortfolioMetaResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioMetaResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'portfolioId': json['portfolioId'],
        'portfolioName': json['portfolioName'],
        'portfolioType': !exists(json, 'portfolioType') ? undefined : json['portfolioType'],
    };
}

export function PortfolioMetaResponseDtoToJSON(value?: PortfolioMetaResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'portfolioId': value.portfolioId,
        'portfolioName': value.portfolioName,
        'portfolioType': value.portfolioType,
    };
}

