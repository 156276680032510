import {
  ResponseError as MttsResponseError,
  PipelineStatusV1Dto,
} from '@@/generated/mtts/openapi';
import {
  ResponseError,
  PipelineStatusV1DtoStateEnum,
} from '@@/generated/openapi';
import { PipelineError } from './errors';
import { AllPipelineType } from '@/components/CurrentPipelineStep/CurrentPipelineStep';

type PossiblePipelineTypes = AllPipelineType | PipelineStatusV1Dto;

export async function waitForPipeline<T>(
  postMethod: () => Promise<PossiblePipelineTypes>,
  getPipelineStatus: (id: string) => Promise<PossiblePipelineTypes>,
  getMethod?: (pipelineResponse: PossiblePipelineTypes) => Promise<T>,
  ignoreNotFound?: boolean,
): Promise<T | null> {
  const pipeline = await postMethod();
  const pipelineId =
    'pipelineId' in pipeline
      ? pipeline.pipelineId
      : 'spec' in pipeline && 'id' in pipeline.spec
      ? pipeline.spec.id
      : '';

  while (true) {
    await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before the next attempt
    try {
      const pipelineResponse = await getPipelineStatus(String(pipelineId));

      let pipelineStatus: string | PipelineStatusV1DtoStateEnum | undefined;
      if ('spec' in pipelineResponse) {
        pipelineStatus = pipelineResponse.status?.state;
      } else if ('status' in pipelineResponse) {
        pipelineStatus = pipelineResponse.status;
      }

      if (pipelineStatus === PipelineStatusV1DtoStateEnum.Failed) {
        throw new PipelineError(pipelineResponse as AllPipelineType);
      }

      if (pipelineStatus !== PipelineStatusV1DtoStateEnum.Completed) {
        continue;
      }

      const response = getMethod ? await getMethod(pipelineResponse) : null;
      return response;
    } catch (e: unknown) {
      if (
        (e instanceof MttsResponseError || e instanceof ResponseError) &&
        e.response.status === 404 &&
        ignoreNotFound
      ) {
        continue;
      } else {
        console.error(e);
        throw e;
      }
    }
  }
}
