import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TopicV1Dto, EnvironmentClassV1Dto } from '@@/generated/openapi';
import Input from '@/components/Input/Input';
import TextArea from '@/components/Textarea/Textarea';
import FormWrapper from '@/components/FormWrapper/FormWrapper';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import InputStepper from '@/components/InputStepper/InputStepper';
import { FormInputGroup } from '@/components/FormInputGroup/FormInputGroup';
import { SchemaSelect } from './SchemaSelect';
import { McCheckbox } from '@maersk-global/mds-react-wrapper/components-core/mc-checkbox';
import { eventsProcessingPage, defaultErrorMessages } from '@text';
import { useUserBelongsToAppTeam } from '@/hooks/useUserBelongsToAppTeam';
import { lowercaseOnly } from '@/helpers/regex';

const schema = yup.object({
  name: yup
    .string()
    .required('Name is required')
    .matches(lowercaseOnly, 'Name can only contain lowercase letters'),
  description: yup.string().required('Description is required'),
  spec: yup
    .object({
      partitionNumber: yup
        .number()
        .required(defaultErrorMessages.required)
        .min(1, defaultErrorMessages.min),
      retentionHours: yup
        .number()
        .required(defaultErrorMessages.required)
        .min(1, defaultErrorMessages.min),
      keySchema: yup.object(),
      valueSchema: yup.object().required(defaultErrorMessages.required),
    })
    .required(),
});

const defaultTopic: TopicV1Dto = {
  name: '',
  applicationCode: '',
  tags: [],
  environmentClass: 'NONPROD',
  description: '',
  spec: {
    dataSecurityClassification: 'INTERNAL',
    isSharable: true,
    partitionNumber: 3,
    retentionHours: 24,
    replicationFactor: 3,
    segmentBytes: 1073741824,
    cleanupPolicy: 'DELETE',
    keySchema: undefined,
    valueSchema: undefined,
  },
};

type TopicFormProps = {
  initialData?: TopicV1Dto;
  onSubmit: (data: TopicV1Dto) => Promise<void>;
  onCancel?: () => void;
  isLoading?: boolean;
  mode: 'create' | 'edit';
};

export const TopicForm = ({
  initialData = defaultTopic,
  onSubmit,
  isLoading,
  mode = 'create',
}: TopicFormProps) => {
  const [needKeySchema, setNeedKeySchema] = useState(
    initialData?.spec?.keySchema !== undefined,
  );
  const userBelongsToAppTeam = useUserBelongsToAppTeam();
  const form = useForm<TopicV1Dto>({
    // @ts-expect-error schema is not properly typed
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialData,
      name: initialData?.name ?? '',
      environmentClass:
        initialData?.environmentClass ?? EnvironmentClassV1Dto.Nonprod,
      description: initialData?.description ?? '',
      spec: initialData?.spec ?? defaultTopic.spec,
    },
  });

  const handleSubmit = form.handleSubmit(onSubmit);

  return (
    <FormWrapper form={form} schema={schema}>
      <form onSubmit={handleSubmit}>
        <FormInputGroup width="normal">
          {mode === 'create' && (
            <Input
              name="name"
              text={eventsProcessingPage.topicForm.name}
              disabled={isLoading}
              data-testid="topic-name"
              readonly={!userBelongsToAppTeam}
            />
          )}
          <TextArea
            name="description"
            text={eventsProcessingPage.topicForm.description}
            data-testid="description"
            rows={3}
            disabled={isLoading}
            readonly={!userBelongsToAppTeam}
          />
          <InputStepper
            name="spec.partitionNumber"
            min={1}
            text={eventsProcessingPage.topicForm.partitions}
            disabled={isLoading}
            readonly={!userBelongsToAppTeam}
          />
          <InputStepper
            type="number"
            suffix="hours"
            name="spec.retentionHours"
            min={1}
            text={eventsProcessingPage.topicForm.retention}
            disabled={isLoading}
            readonly={!userBelongsToAppTeam}
          />
          <FormInputGroup width="normal" appearance="tetriary" bordered>
            <SchemaSelect
              text={eventsProcessingPage.topicForm.valueSchema}
              mode={mode}
              name="spec.valueSchema"
              disabled={isLoading || mode === 'edit'}
              readonly={!userBelongsToAppTeam}
            />

            {mode === 'create' && (
              <McCheckbox
                name="needKeySchema"
                label={eventsProcessingPage.topicForm.keySchema.checkbox}
                value={needKeySchema ? 'true' : 'false'}
                change={() => {
                  setNeedKeySchema(!needKeySchema);
                  if (!needKeySchema) {
                    form.setValue('spec.keySchema', undefined);
                  }
                }}
              />
            )}
            {needKeySchema && (
              <SchemaSelect
                text={eventsProcessingPage.topicForm.keySchema}
                mode={mode}
                name="spec.keySchema"
                disabled={isLoading || mode === 'edit'}
              />
            )}
          </FormInputGroup>
          <div className="form-actions">
            <McButton
              loading={isLoading}
              type="submit"
              disabled={!userBelongsToAppTeam}
            >
              {mode === 'create'
                ? eventsProcessingPage.topicForm.submit.create
                : eventsProcessingPage.topicForm.submit.edit}
            </McButton>
          </div>
        </FormInputGroup>
      </form>
    </FormWrapper>
  );
};
