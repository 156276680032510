import { FC, memo } from 'react';
import { createPortal } from 'react-dom';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';

interface UnsavedChangesModalProps {
  isOpen: boolean;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
}

export const UnsavedChangesModal: FC<UnsavedChangesModalProps> = memo(
  function UnsavedChangesModal({
    isOpen,
    onConfirm,
    onCancel,
  }: UnsavedChangesModalProps) {
    if (!isOpen) return null;

    return createPortal(
      <ConfirmationModal
        title="You have unsaved changes"
        onSubmit={onConfirm}
        submitText="Discard changes"
        submitAppearance="error"
        onClose={onCancel}
        text="You have unsaved changes. Do you want to proceed?"
        open={true}
      />,
      document.body,
    );
  },
);
