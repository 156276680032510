import { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { createPortal } from 'react-dom';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import { DiffVisualization } from '../../DiffVisualization/DiffVisualization';

interface ConfirmChangesModalProps {
  isOpen: boolean;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
  initialValues: Record<string, unknown>;
  ignorePaths?: string[];
}

export const ConfirmChangesModal: FC<ConfirmChangesModalProps> = memo(
  function ConfirmChangesModal({
    isOpen,
    onConfirm,
    onCancel,
    initialValues,
    ignorePaths = [],
  }: ConfirmChangesModalProps) {
    const form = useFormContext();

    if (!isOpen) return null;
    const currentValues = form.getValues();

    return createPortal(
      <ConfirmationModal
        open={isOpen}
        title={'Confirm changes'}
        submitAppearance={'primary'}
        submitText={'Confirm'}
        onSubmit={onConfirm}
        onClose={onCancel}
        text={
          <DiffVisualization
            left={initialValues}
            right={currentValues}
            ignorePaths={ignorePaths}
          />
        }
      />,
      document.body,
    );
  },
);
