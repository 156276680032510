import { UrlParams } from '@/helpers/urls';
import ReactMarkdown, { Components } from 'react-markdown';
import directive from 'remark-directive';
import { visit } from 'unist-util-visit';
import React, { FC } from 'react';
import { InternalLink } from './InternalLink';
import { parseTemplateString } from '@/helpers/utils';
import { TranslationKey } from './TranslationKey';

function reactMarkdownRemarkDirective() {
  // @ts-expect-error not properly typed
  return function transformer(tree) {
    visit(
      tree,
      ['textDirective', 'leafDirective', 'containerDirective'],
      (node) => {
        node.data = {
          hName: node.name,
          hProperties: node.attributes,
          ...node.data,
        };
        return node;
      },
    );
  };
}

export interface InternalLinkProps {
  node: {
    properties: UrlParams;
    children: React.ReactNode;
  };
  children: React.ReactNode;
}

const components: Partial<
  Components & {
    internalLink: typeof InternalLink;
    transKey: typeof TranslationKey;
  }
> = {
  internalLink: InternalLink,
  transKey: TranslationKey,
  a: ({ href, children }) => (
    <a
      href={href}
      target="_blank"
      style={{
        textDecoration: 'underline',
        textUnderlineOffset: '0.2em',
      }}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ),
};

type MdContext = {
  [key: string]: MdContext | string | undefined;
};

interface MdProps {
  children: string;
  context?: MdContext;
}

const Md: FC<MdProps> = ({ children = '', context = {} }) => {
  const template = parseTemplateString(children, context);
  return (
    <div className="App">
      <ReactMarkdown
        remarkPlugins={[directive, reactMarkdownRemarkDirective]}
        components={components}
      >
        {template}
      </ReactMarkdown>
    </div>
  );
};

export default Md;
