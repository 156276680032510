import useError from '@/hooks/useError';
import {
  getErrorMessage,
  isProblemDetail,
  PipelineError,
} from '@/helpers/errors';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { McNotification } from '@maersk-global/mds-react-wrapper/components-core/mc-notification';
import classes from './ErrorNotification.module.scss';
import { FetchError, ProblemDetailErrorsInnerDto } from '@@/generated/openapi';
import { defaultErrorMessages } from '@text';
import Md from '../Md/Md';

const ErrorNotification = () => {
  const { error, setError, show } = useError();
  const [open, setOpen] = useState(false);

  const normalizedError: {
    title: string;
    id?: string;
    errors?: ProblemDetailErrorsInnerDto[];
  } = useMemo(() => {
    if (isProblemDetail(error)) {
      return {
        title: `${error.status} - ${error.title}`,
        id: error.traceId,
        errors: error.errors,
      };
    } else if (error instanceof PipelineError) {
      return {
        title: error.name,
        message: error.detail,
      };
    } else if (error instanceof Error) {
      return {
        title: error.message,
      };
    } else if (error instanceof FetchError) {
      return {
        title: error.message,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } else if ((error as any)?.type === 'cors') {
      return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        title: `${(error as any)?.status ?? ''} Network Error`,
      };
    }
    return {
      title: 'Unknown Error',
    };
  }, [error]);

  if (!error || !show) {
    return null;
  }

  const copyErrorToClipboard = async () => {
    await navigator.clipboard.writeText(JSON.stringify(error, null, 2));
  };

  const handleClose = () => {
    setError();
    setOpen(false);
  };

  if (error instanceof PipelineError) {
    return (
      <div className={classes.outerCont}>
        <div className={classes.innerCont}>
          <McNotification appearance="error" heading={error.name}>
            <div className={classes.errorDetails}>
              <Md>{error.detail || 'Unknown error'}</Md>
            </div>
          </McNotification>
        </div>
      </div>
    );
  }

  if (normalizedError.errors && normalizedError.errors[0]?.code) {
    return (
      <McNotification appearance="error">
        <div className={classes.errorDetails}>
          <ul>
            {normalizedError.errors?.map(({ field, code }) => (
              <li key={`${field}-${code}`}>
                <Md>
                  {getErrorMessage(code, defaultErrorMessages) ||
                    'Unknown error'}
                </Md>
              </li>
            ))}
          </ul>
        </div>
      </McNotification>
    );
  }

  return (
    <div className={classes.outerCont}>
      <div className={classes.innerCont}>
        <McNotification
          appearance="error"
          width="full-width"
          heading={normalizedError.title}
          closable={true}
          close={handleClose}
        >
          {normalizedError.id && (
            <>
              <div>Trace Id: {normalizedError.id}</div>
              <div className={classes.viewMoreCont}>
                <McButton
                  variant="plain"
                  appearance="neutral"
                  label={open ? 'Hide Full Error' : 'View Full Error'}
                  onClick={() => setOpen(!open)}
                  fit="small"
                />
              </div>
            </>
          )}
        </McNotification>

        {(open || !normalizedError.id) && (
          <div className={classes.fullErrorCont}>
            <code>
              <pre className={classes.fullError}>
                {JSON.stringify(error, null, 2)}
              </pre>
            </code>
          </div>
        )}
      </div>

      <div>
        <McButton
          icon="copy"
          hiddenlabel={true}
          variant="plain"
          appearance="neutral"
          onClick={copyErrorToClipboard}
        />
      </div>
    </div>
  );
};

export default ErrorNotification;
