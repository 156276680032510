/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RequestDto } from './RequestDto';
import {
    RequestDtoFromJSON,
    RequestDtoFromJSONTyped,
    RequestDtoToJSON,
} from './RequestDto';

/**
 * 
 * @export
 * @interface RequestListDto
 */
export interface RequestListDto {
    /**
     * 
     * @type {number}
     * @memberof RequestListDto
     */
    totalRecords?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestListDto
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestListDto
     */
    currentPage?: number;
    /**
     * 
     * @type {Array<RequestDto>}
     * @memberof RequestListDto
     */
    content?: Array<RequestDto>;
}

/**
 * Check if a given object implements the RequestListDto interface.
 */
export function instanceOfRequestListDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RequestListDtoFromJSON(json: any): RequestListDto {
    return RequestListDtoFromJSONTyped(json, false);
}

export function RequestListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalRecords': !exists(json, 'totalRecords') ? undefined : json['totalRecords'],
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'currentPage': !exists(json, 'currentPage') ? undefined : json['currentPage'],
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(RequestDtoFromJSON)),
    };
}

export function RequestListDtoToJSON(value?: RequestListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalRecords': value.totalRecords,
        'totalPages': value.totalPages,
        'currentPage': value.currentPage,
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(RequestDtoToJSON)),
    };
}

